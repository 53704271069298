import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from './loader';

// For Public pages
const AuthRoute = (WrappedComponent) => {
  return (props) => {
    const userData = useSelector((state) => state.user.userData);
    const Router = useRouter();
    useEffect(() => {
      const tokenNotAvailable =
        !localStorage.getItem('access_token') ||
        !localStorage.getItem('refresh_token');
      if (userData?.id && !tokenNotAvailable) {
        if (!userData.is_admin) {
          Router.push('/home');
        }
        else Router.push('/admin/home');
      }
    }, [userData]);
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('access_token');
      if (!localStorage.getItem('access_token') || !localStorage.getItem('refresh_token')) return <WrappedComponent {...props} />;
      return <Loader isLoading={true} />;
    }
    return null;
  };
};

export default AuthRoute;
