import styled from 'styled-components';
import LoadingSpinner from '../../assets/images/loading.svg';
import SecondSpinner from '../../assets/images/dataLoader.svg';

const LoadingPage = styled.div`
  position: absolute;
  background-color: ${({background}) => background};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${(props) => (props.zIndex ? 1001 : 1000)};
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  transition: ${({ isLoading }) => (isLoading ? 0 : 0.3)}s;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};
  font-weight: bold;
  color: #4b4b4d;
  text-transform: uppercase;
`;

const LoadingWrapper = styled.div`
  background-color: #fff;
  margin: ${({ isAbsolute }) => !isAbsolute && 'auto'};
  width: ${({ isAbsolute }) => isAbsolute && '100%'};
  height: ${({ isAbsolute }) => isAbsolute && '100%'};
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'static')};
  left: 0;
  top: 0;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 100)};
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  transition: ${({ isLoading }) => (isLoading ? 0 : 0.3)}s;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};
`;

const SpinnerContainer = styled.div`
  svg {
    animation: rotation 2s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const LoaderText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 15px;
`;

const Loader = ({ isLoading, zIndex = false, background = '#fff' }) => (
  <LoadingPage isLoading={isLoading} zIndex={zIndex} background={background} >
    <LoadingSpinner />
  </LoadingPage>
);

export default Loader;

export const DataLoader = ({ isLoading, isAbsolute, zIndex = 101 }) => (
  <LoadingWrapper isLoading={isLoading} isAbsolute={isAbsolute} zIndex={zIndex}>
    <SpinnerContainer>
      <SecondSpinner />
    </SpinnerContainer>
    <LoaderText>Loading Data</LoaderText>
  </LoadingWrapper>
);