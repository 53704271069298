import React, { useState } from 'react';
import Features from './features-new';
import HeroComp from './hero';
import Roles from './roles';
import useWindowSize from '../../utils/hooks/use-window-size';
import HomeFooter from './footer';
import ChurchOS from './church-os';
import ChurchBuilders from './builders';
import Costing from './costing';
import Reviews from './reviews';
import FAQs from './faqs';
import { useEffect } from 'react';
import { useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Banner, DeadlineText, FreeText, SpringText } from './index.styles';


gsap.registerPlugin(ScrollTrigger);

export const darkSections = [
  // '#hero-section',
  '#quote-section',
  '#church-builders',
  '#costing',
  '#faq',
];

export const HomePageBanner = () =>
  (
    <Banner>
      <SpringText>☀️ Summer Savings</SpringText>
      <FreeText>Free white-glove account setup ($299 value)</FreeText>
      <DeadlineText>Available until July 31, 2023</DeadlineText>
    </Banner>
  );

const HomePageComp = () => {
  const { width } = useWindowSize();
  const bodyLanding = useRef();
  const commonQuestions = useRef();
  const [showModal, setShowModal] = useState({
    open: false,
    videoID: '',
  });
  const [fixHeader, setFixHeader] = useState(false);
  const [lightMode, setLightMode] = useState(true);

  useEffect(() => {
    function updateScrollPosition() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll > 0) {
        setFixHeader(true);
      } else {
        setFixHeader(false);
      }
    }

    if (window) {
      window.addEventListener('scroll', updateScrollPosition, false);
    }
    return function cleanup() {
      if (window) {
        window.removeEventListener('scroll', updateScrollPosition, false);
      }
    };
  }, []);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const element = bodyLanding.current;
      // Turn header to black
      darkSections.forEach((el) => {
        ScrollTrigger.create({
          trigger: element.querySelector(el),
          start: 'top 48px',
          end: 'bottom 48px',
          onEnter: () => setLightMode(true),
          onLeave: () => setLightMode(false),
          onEnterBack: () => setLightMode(true),
          onLeaveBack: () => setLightMode(false),
        });
      });
    }, bodyLanding);
    return () => ctx.revert();
  }, []);

  // For banner
  useEffect(() => {
    let ctx = gsap.context(() => {
      const element = bodyLanding.current;
      ScrollTrigger.create({
        trigger: element.querySelector('#hero-section'),
        start: '-1px 48px',
        end: 'bottom 48px',
        onEnter: () => setLightMode(true),
        onLeave: () => setLightMode(false),
        onEnterBack: () => setLightMode(true),
        onLeaveBack: () => setLightMode(false),
      });
    }, bodyLanding);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={bodyLanding}>
      <HeroComp
        fixHeader={fixHeader}
        showModal={showModal.open}
        width={width}
        lightMode={lightMode}
        commonQuestions={commonQuestions}
        withBanner={false}
      />
      <Features width={width} />
      <Roles width={width} />
      <ChurchBuilders />
      <Reviews width={width} homepage={true} />
      <Costing />
      <ChurchOS />
      <FAQs width={width} commonQuestions={commonQuestions} />
      <HomeFooter width={width} />
    </div>
  );
};

export default HomePageComp;
