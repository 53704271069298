import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorContainer from '../components/error-boundary/index';
import AuthRoute from '../components/auth-route';
import HomePageComp from '../components/homepage';

const LandingPage = () => {

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorContainer} onReset={() => {}}>
        <HomePageComp />
      </ErrorBoundary>
    </>
  );
};

export default AuthRoute(LandingPage);
